.introLogo{
  position: fixed;
  z-index: -1;
}

.navbar{
    box-shadow: 0 0 7px black;
    height: 50px;
    width: 100vw;
    position: fixed;
    display: flex;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255);    
}

.heroSection{
    width: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.blackButton{
    background-color: rgb(50,50,50);
}
.blackButton:hover{
    background-color: rgb(75,75,75);
}

.benCard{
    height: 300px;
    width: 300px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}

.BenCardContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 60px;
}

.founders{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

}

@media only screen and (max-width: 600px) {
    .BenCardContainer{
        flex-direction: column;
    }
}

@media only screen and (max-width: 1280px){
    .founders{
        width: 700px;
    }
}

@media only screen and (max-width: 700px){
    .founders{
        width: auto;
    }
}

faqs {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
  }
  
  .faqs .faq {
    margin: 15px;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  
  .faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
  }
  
  .faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
  }
  
  .faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
  }
  
  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
  }
  
  .faqs .faq.open .faq-question::after {
    content: "-";
  }
  
  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
  }
  
  .buttonBox{
    height: 50px;
    width: 50px;
    border-radius: 7px;
    background-color: rgb(230,230,230);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonBox:hover{
    background-color: rgb(200,200,200);
  }

  .registerContainer{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

.AboutUsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

@media only screen and (min-width: 600px) {
  .AboutUsContainer{
    display: flex;
    flex-direction: row;
  }
  .AboutUsHeading{
    rotate: 270deg;
  }
}